
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  computed,
} from "vue";
import MBDatatablePlus from "@/components/mb-datatable/DataTablePlus.vue";
import { MenuComponent } from "@/assets/ts/components";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatUtcDate,
  setModuleBCN,
  settlementLastMonth,
  settlementLastMonthDate,
  settlementLastWeekDate,
  settlementLastDayDate,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import {
  FilterOption,
  SortOption,
  SortCounter,
  TaggingItem,
  TableHeader,
} from "@/core/directive/interface/common";
import { checkPermissionFlag } from "@/directives/permission";
import { CommonDateType } from "@/core/directive/type/common";
import moment from "moment";
import { useComputedFn } from "@/core/directive/function/common";
import { KycType, entityStatus } from "@/core/directive/type/kyc";
import {
  getEntityStatus,
  getKycCommonTypeParams,
  getSettlementEntityTypeParams,
} from "@/core/directive/function/kyc";

export default defineComponent({
  name: "settlement-entity-index-list",
  components: {
    MBDatatablePlus,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();

    const tableLoading = ref(false);
    const statisticPicker = ref();
    const filterRef = ref();
    const disabledExport = ref(false);
    const tableData = ref<Array<any>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const disabledExportLastWeek = ref(false);
    const disabledExportLastMonth = ref(false);
    const checkedArr = ref<Array<number>>([]);
    const sortOrder = ref("desc");
    const sortLabel = ref("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);

    const tableHeader = computed(() => {
      let arr: TableHeader[] = [
        {
          name: t("kyc.list.id"),
          key: "id",
        },
      ];
      if (getKycCommonTypeParams.value.entityType === KycType.Juridical) {
        arr.push({
          name: t("kyc.list.companyName"),
          key: "company_name",
        });
      }
      if (getKycCommonTypeParams.value.entityType === KycType.Natural) {
        arr.push({
          name: t("kyc.list.name"),
          key: "lastname",
        });
      }
      arr.push(
        {
          name: t("kyc.list.entityStatus"),
          key: "status",
        },
        {
          name: t("kyc.list.lastUpdateTime"),
          key: "updated_at",
        },
        {
          name: t("common.actions"),
          key: "actions",
          sortable: false,
        }
      );
      return arr;
    });

    const options = ref({
      defaultSort: [...sortOptions.value] as SortOption[],
      filter_group: [] as FilterOption[],
      updated_at: [] as CommonDateType[],
      dateTypeOptions: [],
      source: (entityStatus.Deactivated +
        "," +
        entityStatus.Activated +
        "," +
        entityStatus.Unverified) as any,
      sourceLoading: false,
      sourceOptions: [
        {
          label: t("common.all"),
          value:
            entityStatus.Deactivated +
            "," +
            entityStatus.Activated +
            "," +
            entityStatus.Unverified,
        },
        {
          label: t("kyc.status.deactivated"),
          value: entityStatus.Deactivated,
        },
        {
          label: t("kyc.status.activated"),
          value: entityStatus.Activated,
        },
        {
          label: t("kyc.status.unverified"),
          value: entityStatus.Unverified,
        },
      ] as TaggingItem[],
      searchOptions: [] as TaggingItem[],
      filter_date: "last_update_at",
      totalArray: [] as any[],
    });

    const getDateType = computed(() => {
      let format = "YYYY-MM-DD",
        valueFormat = "YYYY-MM-DD";
      return {
        format,
        valueFormat,
      };
    });

    const allDate = [
      new Date("2022-02-01T00:00:00.000Z"),
      settlementLastDayDate({
        value: 0,
        isStart: false,
        format: getDateType.value.valueFormat,
      }),
    ];

    const disabledDate = (time: Date) => {
      const startDate = new Date("2022-01-31");
      return (
        time.getTime() > Date.now() || time.getTime() < startDate.getTime()
      );
    };

    const defaultDate = () => {
      // options.value.last_update_at = [
      //   new Date("2022-02-01T00:00:00.000Z").toISOString(),
      //   settlementLastDayDate({
      //     value: 0,
      //     isStart: false,
      //     format: getDateType.value.valueFormat,
      //   }),
      // ];
    };

    const setOtherFilter = computed(() => {
      let arr: FilterOption[] = [];
      if (options.value.updated_at && options.value.updated_at.length > 0) {
        arr.push({
          field: options.value.filter_date,
          value: options.value.updated_at[0]!,
          condition: "gteq",
        });
        arr.push({
          field: options.value.filter_date,
          value: options.value.updated_at[1]!,
          condition: "lteq",
        });
      }
      if (typeof options.value.source === "string") {
        arr.push({
          field: "status",
          value: options.value.source.split(","),
          condition: "in",
        });
      } else {
        arr.push({
          field: "status",
          value: options.value.source,
          condition: "eq",
        });
      }

      return arr;
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    const isCanExport = computed((): boolean => {
      return checkPermissionFlag({ auth: ["export"] });
    });

    /**
     * @description: 请求数据
     */
    const getList = async () => {
      tableLoading.value = true;

      const { data } =
        await getSettlementEntityTypeParams.value.getSettlementEntityListApi(
          setCommonFilter.value
        );
      tableLoading.value = false;

      if (data.code === 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    /**
     * @description: 获取列表
     */
    const updateList = () => {
      currentPage.value = 1;
      getList();
    };

    /**
     * @description: 重置过滤器（只修改参数）
     */
    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    /**
     * @description: 重置过滤器（拉取数据）
     */
    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    /**
     * @description: 重置排序（拉取数据）
     */
    const handleSortReset = () => {
      resetColumnSort();
      updateList();
    };

    /**
     * @description: 重置排序（只修改参数）
     */
    const resetColumnSort = () => {
      sortOptions.value.splice(
        0,
        sortOptions.value.length,
        ...options.value.defaultSort
      );
      sortOrder.value = "desc";
      sortLabel.value = "";
    };

    /**
     * @description: 判断是否是默认视图
     */
    const isDefaultView = computed(() => {
      return (
        JSON.stringify(options.value.defaultSort) ===
          JSON.stringify(sortOptions.value) &&
        sortCounterFlag.length === 0 &&
        JSON.stringify(options.value.filter_group) ===
          JSON.stringify(setCommonFilter.value.filter_group) &&
        options.value.source === entityStatus.Activated
      );
    });

    const sortCounterFlag = [] as SortCounter[];

    /**
     * @description: 排序独立计数
     */
    const sortCounter = (field: string) => {
      if (sortCounterFlag.some((item) => item.field === field)) {
        sortCounterFlag.forEach((item) => {
          if (item.field === field) {
            item.counter = item.counter + 1;
          }
        });
      } else {
        sortCounterFlag.splice(0, sortCounterFlag.length, {
          field: field,
          counter: 1,
        });
      }
    };

    /**
     * @description: 排序3次判断
     */
    const onSortCounterThree = (val) => {
      let resetFlag = false;
      sortCounterFlag.forEach((item) => {
        if (item.field === val.columnName) {
          if (item.counter === 3) {
            sortCounterFlag.splice(0, sortCounterFlag.length);
            resetFlag = true;
          }
        }
      });
      return resetFlag;
    };

    /**
     * @description: 切换日期选择器时
     */
    const handleFilter = (val) => {
      resetColumnSort(); // 重置排序
      updateList();
    };

    /**
     * @description: 切换状态筛选器时
     */
    const handleSourceSelect = (val) => {
      resetFilter();
      resetColumnSort();
      if (val === "") {
        options.value.source = entityStatus.Activated;
      }
      updateList();
    };

    /**
     * @description: 重置按钮点击时（拉取数据）
     */
    // const reset = () => {
    //   resetColumnSort();
    //   sortCounterFlag.splice(0, sortCounterFlag.length);
    //   options.value.source = KycStatus.Activated;
    //   updateList();
    // };

    /**
     * @description: 切换排序时
     */
    const onColumnSort = (val: any) => {
      sortCounter(val.columnName);
      const resetFlag = onSortCounterThree(val);
      if (resetFlag) {
        handleSortReset();
      } else {
        let thisDirection = "";
        sortCounterFlag.forEach((item) => {
          if (item.field === val.columnName) {
            if (item.counter === 1) {
              thisDirection = "asc"; //第一次正序
            }
            if (item.counter === 2) {
              thisDirection = "desc"; //第二次倒序
            }
          }
        });
        sortOrder.value = thisDirection;
        const newSortRule = [] as SortOption[];
        newSortRule.push({
          field: val.columnName,
          direction: thisDirection,
        });
        sortOptions.value.splice(0, sortOptions.value.length, ...newSortRule);
        sortLabel.value = val.columnName;
        updateList();
      }
    };

    /**
     * @description: 切换页码时
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getList();
    };

    /**
     * @description: 切换记录条数时
     */
    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    /**
     * @description: 搜索时
     */
    const debounceSearch = _.debounce(updateList, 1000);
    const searchItems = () => {
      debounceSearch();
    };
    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    const getStatus = useComputedFn(getEntityStatus);

    const init = () => {
      setModuleBCN(t, route);
      updateList();
    };

    onMounted(() => {
      options.value.defaultSort.splice(
        0,
        options.value.defaultSort.length,
        ...sortOptions.value
      );
      defaultDate();
      options.value.filter_group = [...setCommonFilter.value.filter_group];
      init();
      // if (store.getters.getAliveList.length === 0) {
      //   init();
      // }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      moment,
      formatUtcDate,
      formatDate,
      settlementLastWeekDate,
      settlementLastMonth,
      settlementLastMonthDate,
      getSettlementEntityTypeParams,
      KycType,
      tableLoading,
      statisticPicker,
      filterRef,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      sortOrder,
      sortLabel,
      options,
      isCanExport,
      disabledExport,
      disabledExportAll,
      disabledExportSelected,
      disabledExportLastWeek,
      disabledExportLastMonth,
      checkedArr,
      getDateType,
      handleFilter,
      handleFilterReset,
      disabledDate,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      isDefaultView,
      // reset,
      resetColumnSort,
      handleSortReset,
      onColumnSort,
      handleSourceSelect,
      getStatus,
    };
  },
});
