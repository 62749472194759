import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5dc7a36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-custom-main" }
const _hoisted_2 = { class: "app-content flex-column-fluid" }
const _hoisted_3 = { class: "app-container" }
const _hoisted_4 = { class: "d-flex flex-column gap-10" }
const _hoisted_5 = { class: "card card-flush" }
const _hoisted_6 = { class: "card-header align-items-center py-5 gap-2 gap-md-5" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  class: "card-toolbar flex-row-fluid justify-content-end gap-5",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_12 = { class: "business-date-select-box day rounded" }
const _hoisted_13 = { class: "source-select-box rounded overflow-hidden" }
const _hoisted_14 = { class: "card-body pt-0" }
const _hoisted_15 = { class: "text-nowrap w-100px" }
const _hoisted_16 = { class: "w-200px" }
const _hoisted_17 = { class: "text-gray-400 fs-7" }
const _hoisted_18 = { class: "w-200px" }
const _hoisted_19 = { class: "text-gray-400 fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                    class: "form-control form-control-solid w-250px ps-14",
                    placeholder: _ctx.t('common.search')
                  }, null, 40, _hoisted_10), [
                    [_vModelText, _ctx.search]
                  ]),
                  _withDirectives(_createElementVNode("span", {
                    class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle cursor-pointer",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
                  }, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                  ], 512), [
                    [_vShow, _ctx.search != '']
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_el_date_picker, {
                    "popper-class": "date-popover",
                    class: _normalizeClass(["w-100", {
                      'has-value':
                        _ctx.options.updated_at && _ctx.options.updated_at.length,
                    }]),
                    style: _normalizeStyle(`--placeholder:'${_ctx.$t('common.PickDateRange')}'`),
                    modelValue: _ctx.options.updated_at,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.options.updated_at) = $event)),
                    type: "daterange",
                    format: _ctx.getDateType.format,
                    "value-format": _ctx.getDateType.valueFormat,
                    onChange: _ctx.handleFilter,
                    disabled: _ctx.tableLoading,
                    "disabled-date": _ctx.disabledDate,
                    "range-separator": _ctx.$t('common.dateTo'),
                    "append-to-body": false
                  }, null, 8, ["class", "style", "modelValue", "format", "value-format", "onChange", "disabled", "disabled-date", "range-separator"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.options.source,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.options.source) = $event)),
                    teleported: false,
                    loading: _ctx.options.sourceLoading,
                    disabled: _ctx.tableLoading,
                    onChange: _ctx.handleSourceSelect,
                    placement: "bottom-start",
                    "fit-input-width": true
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.sourceOptions, (item, key) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: key,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "loading", "disabled", "onChange"])
                ])
              ])
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_MBDatatablePlus, {
                "table-data": _ctx.tableData,
                "table-header": _ctx.tableHeader,
                order: _ctx.sortOrder,
                "diy-order": true,
                "sort-label": _ctx.sortLabel,
                "current-page": _ctx.currentPage,
                total: _ctx.total,
                "rows-per-page": _ctx.pageSize,
                showExpandBtn: false,
                onCurrentChange: _ctx.onCurrentPageChange,
                onItemsPerPageChange: _ctx.onRowsPerPageChange,
                onDiySortClick: _ctx.onColumnSort
              }, {
                "cell-id": _withCtx(({ row: item }) => [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(item?.id ? item?.id : "--"), 1)
                ]),
                "cell-company_name": _withCtx(({ row: item }) => [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(item.company_name ? item.company_name : "--"), 1),
                  _createElementVNode("div", _hoisted_17, _toDisplayString((item?.city ? item?.city : "--") +
                      ", " +
                      (item?.country ? item?.country : "--")), 1)
                ]),
                "cell-lastname": _withCtx(({ row: item }) => [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(item.lastname ? item.lastname : "--") + " " + _toDisplayString(item.firstname ? item.firstname : "--"), 1),
                  _createElementVNode("div", _hoisted_19, _toDisplayString((item?.city ? item?.city : "--") +
                      ", " +
                      (item?.country ? item?.country : "--")), 1)
                ]),
                "cell-status": _withCtx(({ row: item }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["badge", _ctx.getStatus(item.status).value.color])
                  }, _toDisplayString(item.status_text || "--"), 3)
                ]),
                "cell-updated_at": _withCtx(({ row: item }) => [
                  _createElementVNode("div", null, _toDisplayString(item.updated_at
                        ? _ctx.formatUtcDate(item.updated_at, "YYYY-MM-DD")
                        : "--"), 1)
                ]),
                "cell-actions": _withCtx(({ row: item }) => [
                  _createVNode(_component_router_link, {
                    to: 
                      _ctx.getSettlementEntityTypeParams.routerUrl +
                      '/' +
                      item.id +
                      '/information'
                    ,
                    class: "btn btn-light btn-active-light-primary btn-sm"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("kyc.list.details")), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 1
              }, 8, ["table-data", "table-header", "order", "sort-label", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onDiySortClick"])
            ])), [
              [_directive_loading, _ctx.tableLoading]
            ])
          ])
        ])
      ])
    ])
  ]))
}